import styled from "styled-components";
import {rem} from "../../app/styles";

export const UserSettingsTitle = styled.p`
  color: var(--color-text);
  text-align: center;
  font-size: ${rem(20)};
  font-weight: 800;
  line-height: ${rem(25)};
`

export const UserSettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(30)};
  padding: 5%;
  position: relative;
    padding-top: 80px;
`