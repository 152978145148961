import styled from "styled-components";
import {rem} from "../../app/styles";

interface SupportPageInputProps {
    exceeded: boolean; // Define the exceeded prop
}

interface TicketStatus {
    status: string; // Define the exceeded prop
}

export const SupportPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5%;
  position: relative;
    margin-top: 80px;
`

export const SupportPageTitle = styled.p`
  color: var(--color-text);
  text-align: center;
  font-size: ${rem(20)};
  font-weight: 800;
  line-height: ${rem(25)};
    margin-bottom: ${rem(25)};
`

export const SupportPageInput = styled.div<SupportPageInputProps>`
  display: flex;
  flex-direction: column;
  gap: ${rem(10)};
  margin-top: 40%;
  padding: ${rem(15)};
  align-self: flex-end;
  color: var(--color-text);
  font-size: ${rem(13)};
  font-weight: 400;
  line-height: ${rem(16)};
  border-radius: ${rem(20)};
  border: 2px solid var(--color-bg); /* Added border */
  width: 100%; /* Added width */
  position: relative;

  textarea {
    height: ${rem(250)}; /* Adjust the height accordingly */
    padding: ${rem(10)}; /* Added padding */
      margin-bottom: ${rem(20)};
    resize: none;
    font-size: ${rem(13)};
    font-weight: 400;
    line-height: ${rem(20)};
    outline: none;
      background-color: var(--color-primary);
      color: var(--color-text);
      word-break: break-word;
  }

    input::placeholder {
        color: var(--color-text); /* Set placeholder text color */
        vertical-align: top; /* Align placeholder to start at top position */
    }

  .symbol-counter {
    position: absolute;
    bottom: ${rem(10)};
    right: ${rem(10)};
    color: ${(props) => (props.exceeded ? "red" : "inherit")};
  }
`;



export const TicketWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-third);
    padding-bottom: 13px;
    padding-top: 13px;
`;

export const TicketId = styled.div<TicketStatus>`
    border-radius: 6px;
    padding: 2px 4px 2px 4px;
    background-color: ${(props) => (
        props.status === ('opened' ||  'working') ? '#F5C71A' : '#F6F6F6'
    )};
    color: '#686868';
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    margin-right: ${rem(10)};
`

export const TruncatedText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
    align-items: start;
  max-width: 80%; /* Убедитесь, что div ограничен по ширине */

    font-size: 13px;
    font-weight: 600;
    color: var(--color-text);
    width: 100%;
`;

export const Ticket = styled.div`
    color: var(--color-text);
    width: 100%;
    height: ${rem(70)};
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    padding-left: ${rem(5)};
    padding-top: ${rem(20)};
    padding-bottom: ${rem(20)};
    margin: ${rem(2)};
    margin-right: ${rem(10)};
    text-align: start;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;