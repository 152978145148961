import axios from 'axios';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useEffect, useState } from 'react';
import { authorizeUser } from '../../app/store/userSlice';
import { BackButton, MainButton } from '@vkruglikov/react-telegram-web-app';
import {
  AppLogo,
  AppTitle,
  AuthorizationContent,
  AuthorizationFormActions,
  AuthorizationFormWrapper,
  AuthorizationTitle,
  AuthorizationTypography,
  CloseConventionButton,
  ConventionWrapper,
  OtpVerificationInfo,
  OtpVerificationWrapper,
  PhoneInput,
} from './Authorization.styles';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../ui-kit/Button';
import { OtpInput } from '../../ui-kit/OtpInput';
import { ReactComponent as LogoSvg } from './logo.svg';
import { ReactComponent as ShevronRight } from './chevron-right.svg';
import { ReactComponent as BackIcon } from '../TrainingPageContent/back.svg';
import { BackBtn } from '../TrainingPageContent/TrainingPageContent.styles';
import { phoneMask } from '../../utils/phoneMask';
import { phoneHandler } from '../../utils/phoneHandler';
import ym from 'react-yandex-metrika';
import { CloseIcon } from '../../ui-kit/Icons';

//@ts-ignore
const tg = window.Telegram.WebApp;


const AuthorizationForm = () => {
  const [otp, setOtp] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [requestId, setRequestId] = useState<string>('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleChange = (value: string) => setEnteredOtp(value);
  const [enteredOtp, setEnteredOtp] = useState('');
  const [resendTime, setResendTime] = useState(0); // Countdown timer value in seconds
  const [isResendActive, setIsResendActive] = useState(false); // Flag to indicate whether the countdown is active
  const [isOtpSent, setIsOtpSent] = useState(false); // Flag to indicate whether OTP is already sent
  const [isOtpReSent, setIsOtpReSent] = useState(false); // Flag to indicate whether OTP is already sent
  const [isConventionOpen, setConventionOpen] = useState(true);
  const [isApiSuccess, setApiSuccess] = useState(false);
  const userId = tg?.initDataUnsafe?.user?.id;


  useEffect(() => {
    if (resendTime > 0 && isResendActive) {
      const timer = setInterval(() => {
        setResendTime((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else if (resendTime === 0 && isResendActive) {
      setIsResendActive(false);
    }
  }, [resendTime, isResendActive]);


  async function handleLogIn(phone: string, userId: string) {
    let phoneNum = '+' + phone;
    try {
      // Add a request interceptor to log the full request
      axios.interceptors.request.use((request) => {
        console.log('Full Request Dump:', {
          url: request.url,
          method: request.method,
          headers: request.headers,
          data: request.data,
        });
        return request;
      });

      const result = await axios.post(process.env.REACT_APP_URL + '/login', {
        phone: phoneNum.toString().replace('+', ''),
        telegram_chat_id: userId
      });
      console.log(result);

      if (result.data.data.request_id) {
        const newRequestId = result.data.data.request_id;
        setApiSuccess(true);
        setRequestId(newRequestId);
        setIsResendActive(true);
        setResendTime(60);

        handleGoToOTP(newRequestId, phoneNum.toString().replace('+', ''))
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        tg.showAlert('Внутренняя ошибка! Сообщите в поддержку бота');
      } else if (error.response && error.response.status === 400) {
        const responseData = error.response.data;
        if (responseData.error_type === 1) {
          const ticketNumber = String(responseData.ticket);
          tg.showAlert(
            `Ошибка! Проверьте правильность введенного номера. Информация передана в отдел продаж. \nНомер вашего тикета: #${ticketNumber}`,
          );
        } else if (responseData.error_type === 1) {
          tg.showAlert(
            'Внуренняя ошибка, информация передана команде разработчиков.',
          );
        }
      } else {
        // Handle other error cases
        console.error(error);
      }
    }
  }

  async function handleSendOtp(otp: string, phone: string) {
    const clubId = localStorage.getItem('club');
    try {
      const result = await axios
        .post(process.env.REACT_APP_URL + '/authenticate', {
          request_id: requestId,
          code: enteredOtp,
        })
        .then((res) => {
          if (res.data.success) {
            dispatch(authorizeUser({ phone: phone }));
            localStorage.setItem('token', res.data.data.token);
            localStorage.setItem('club', res.data.data.club_id);
            localStorage.removeItem('otpStatus');
            axios.defaults.headers.common['Authorization'] =
              `Bearer ${localStorage.getItem('token')}`;
            navigate('/schedule');
            const clubId = localStorage.getItem('club');
            setApiSuccess(false);
            ym('reachGoal', `successful_login_${clubId}`);
          } else {
            tg.showAlert('Неверный код, повторите попытку ввода');
          }
        });
    } catch (e) {
      tg.showAlert('Что-то пошло не так..');
      ym('reachGoal', `login_error_${clubId}`);
      // console.log('Что-то пошло не так..')
    }
  }

  async function handleResendOtp() {
    let phoneNum = '+' + phone;
    try {
      const result = await axios.post(process.env.REACT_APP_URL + '/login', {
        phone: phoneNum.toString().replace('+', ''),
      });
      if (result.data.data.request_id) {
        setRequestId(result.data.data.request_id);
        setIsResendActive(true); // Start countdown timer
        setResendTime(60); // Set countdown timer value to 60 seconds
        localStorage.removeItem('otpStatus');
        localStorage.setItem('otpStatus', 'awaiting');
        setTimeout(() => {
          localStorage.removeItem('otpStatus');
          console.log('otpStatus removed from localStorage after 60 seconds');
        }, 80000); // 60 seconds in milliseconds
        setIsOtpReSent(true)
      }
    } catch (e) {
      // Handle error
    }
  }

  const handleRegister = () => {
    navigate('/register');
  };

  const handleGetBack = () => {
    navigate('/')
  };

  const handleGoToOTP = (newRequestId: any, phoneNum: any) => {
    navigate(`/otp?request_id=${newRequestId}&phone=${phoneNum}`)
  }

  return (
      <AuthorizationFormWrapper>
        <AppLogo>
          <LogoSvg />
        </AppLogo>
        <AuthorizationContent>
          <AuthorizationTitle>Вход</AuthorizationTitle>
          <AuthorizationFormActions>
            <PhoneInput
                inputMode={'numeric'}
                variant={'primary'}
                placeholder={'Введите номер телефона'}
                onChange={(e) => setPhone(e.target.value)}
                maskEvent={phoneMask}
            />
            <Button
                disabled={!phone}
                variant={'filled'}
                onClick={async () => await handleLogIn(phoneHandler(phone), userId)}
            >
              <ShevronRight />
            </Button>
          </AuthorizationFormActions>
          <p
              style={{ fontWeight: 'bold', color: '#F5C71A' }}
              onClick={handleRegister}
          >
            Нет абонемента?
          </p>
        </AuthorizationContent>
      </AuthorizationFormWrapper>
    // <>
    //   {isApiSuccess === false ? (
    //
    //   ) : (
    //     <>
    //       <BackButton onClick={handleGetBack} />
    //       <OtpVerificationWrapper>
    //         <OtpVerificationInfo>
    //           <AppTitle>ВЕРИФИКАЦИЯ</AppTitle>
    //           <AuthorizationTypography>
    //             На указанный вами телефон поступит звонок. Введите{' '}
    //             <b>4 последние цифры</b> телефона, с которого поступит звонок
    //           </AuthorizationTypography>
    //         </OtpVerificationInfo>
    //         <OtpInput
    //           value={enteredOtp}
    //           valueLength={4}
    //           onChange={handleChange}
    //         />
    //         {resendTime > 0 ? (
    //           <p className={'text'}>
    //             Отправить повторный звонок через:{' '}
    //             {String(Math.floor(resendTime / 60)).padStart(2, '0')}:
    //             {String(resendTime % 60).padStart(2, '0')}
    //           </p>
    //         ) : isOtpReSent ? (
    //           <p className={'text'}>Код отправлен</p>
    //         ) : (
    //           <p className={'action'}>
    //             <a onClick={handleResendOtp}>Отправить код повторно</a>
    //           </p>
    //         )}
    //         <MainButton
    //           text={'Применить'}
    //           disabled={!Boolean(enteredOtp.length === 4)}
    //           color={'#F5C71A'}
    //           textColor={'#000000'}
    //           onClick={async () =>
    //             await handleSendOtp(enteredOtp, phoneHandler(phone))
    //           }
    //         />
    //         {/*<Button*/}
    //         {/*  variant={'filled'}*/}
    //         {/*  disabled={!Boolean(enteredOtp.length === 4)}*/}
    //         {/*  onClick={async () =>*/}
    //         {/*    await handleSendOtp(enteredOtp, phoneHandler(phone))*/}
    //         {/*  }*/}
    //         {/*>*/}
    //         {/*  Далее*/}
    //         {/*</Button>*/}
    //       </OtpVerificationWrapper>
    //     </>
    //   )}
    //   {isConventionOpen && (
    //     <ConventionWrapper>
    //       <p>
    //         Авторизуясь, вы принимаете условия&nbsp;
    //         <a
    //           href="https://powerbi.ravs.pro/storage/dogovor_profitness.pdf"
    //           target="_blank"
    //           rel="noopener noreferrer"
    //           style={{
    //             fontWeight: 'bold',
    //             textDecoration: 'underline',
    //           }}
    //         >
    //           сублицензионного договора-оферты
    //         </a>
    //       </p>
    //       <CloseConventionButton
    //         shape="circle"
    //         size="small"
    //         onClick={() => setConventionOpen(false)}
    //         icon={<CloseIcon width={10} height={10} />}
    //       />
    //     </ConventionWrapper>
    //   )}
    // </>
  );
};

export default AuthorizationForm;
