import styled from "styled-components";
import {rem} from "../../app/styles";

interface MessageOwner {
    mine: boolean; // Определение, является ли сообщение моим
}

export const MessagesListWrapper = styled.div`
    display: block;
    overflow-y: auto;
    flex-direction: column;
    position: relative;
    margin-top: 10px;
    padding-left: 5%;
    padding-right: 5%;
`;

export const MessageBox = styled.div<MessageOwner>`
    text-align: ${(props) => (props.mine ? 'end' : 'start')};
    display: block;
    margin-bottom: 15px;
`;

export const MessageText = styled.div<MessageOwner>`
    background-color: ${(props) => (props.mine ? '#F5C71A' : '#F6F6F6')};
    color: 'black';
    max-width: 60%;
    border-radius: ${(props) => (props.mine ? '11px 0px 11px 11px' : '0px 11px 11px 11px')};
    text-align: ${(props) => (props.mine ? 'end' : 'start')};
    padding-inline: 12px;
    padding-block: 6px;
    word-wrap: break-word;
    display: inline-block;  /* Добавляем inline-block для правильного выравнивания */
    vertical-align: top;    /* Это поможет выравнивать элементы по верхнему краю */
`;

export const MessageAuthor = styled.div`
    color: white;
    font-weight: bold;
    margin-bottom: 5px;
`;

export const MessageDateTime = styled.div`
    color: white;
    margin-top: 5px;
    font-size: 13px;
`;

export const MessageListTitle = styled.div`
    color: var(--color-text);
    text-align: center;
    font-size: ${rem(20)};
    font-weight: 800;
    line-height: ${rem(25)};
    margin-bottom: ${rem(25)};
    padding-top: 80px;
`;