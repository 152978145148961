import { ComponentType, ReactNode } from "react";

export interface IRoute {
  path: string;
  element: ReactNode;
  layout?: ComponentType;
}

export enum ERoutes {
  "MAIN" = "/",
  "REGISTER" = "/register",
  "OTP" = "/otp",
  "SCHEDULE" = `/schedule/*`,
  "MY_TRAININGS" = "/my_trainings/*",
  "FREEZE" = "/freeze",
  "SUPPORT" = "/support",
  "MESSAGES_LIST" = "/support/tickets/*",
  "NEW_TICKET" = "/support/new-ticket",
  "SHOPPING" = "/shopping",
  "SHOPPINGCART" = "/shopping-cart",
  "PAYMENTSUCCESSFUL" = "/shopping-success",
  "PAYMENTERROR" = "/shopping-error",
  "PROMOS" = "/promos",
  "TRAINING_PAGE" = "schedule/:id",
  "AUTHORIZE" = "/authorize",
  "SETTINGS" = "/settings",
  "PROFILE" = "/profile",
  "FILTERS" = "/filters/*",
  "TRAININGFILTER" = "/filters/training",
  "TRAINERFILTER" = "/filters/trainer",
  "TIMEFROMFILTER" = "/filters/timefrom",
  'CLUB_FILTER' = '/filters/club',
  "ERROR" = '/error'
}
