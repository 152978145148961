import {
    ProfilePageWrapper,
} from "../ProfilePage/ProfilePage.styles";

import { SupportPageWrapper, TicketWrapper, TicketId, TruncatedText, SupportPageTitle } from './SupportPage.styles'
import {useNavigate, useLocation} from "react-router-dom";
import {ReactComponent as ShevronRight} from "../ProfilePage/ShevronRight.svg";
import axios from "axios";
import React, {useCallback, useEffect, useState, useRef} from "react";
import {FadeAnimation} from "../../ui-kit/FadeAnimation";
import {AbsolutePreloader} from "../../ui-kit/Preloader";
import {useSendToAuth} from "../../hooks/useSendToAuth";
import {ERoutes} from "../../app/AppRouter.types";
import {BackButton, MainButton} from "@vkruglikov/react-telegram-web-app";
import {popup, init} from "@telegram-apps/sdk-react"
import {ImageUploader} from "../../ui-kit/ImageUploader";
import Avatar from "../ProfilePage/user.png";
import {setUserProfile} from "../../app/store/userSlice";
import {
    MessageBox,
    MessageText,
    MessagesListWrapper,
    MessageAuthor,
    MessageDateTime,
    MessageListTitle
} from "./MessagesList.styles";
import { format } from "date-fns";
import {Button} from "antd";

// @ts-ignore
const tg = window.Telegram.WebApp;

export interface ITicket {
    id:     string,
    text:   string,
    status: string
}

export interface IMessage {
    id:         string,
    sender_id:  string,
    text:       string,
    author:     string,
    created_at: string
}

export const MessagesList = () => {
    const [messages, setMessages] = useState<IMessage[]>([])
    const sendToAuth = useSendToAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const ticket = location.state?.ticket; // Данные тикета
    const [userId, setUserId] = useState()
    const messagesEndRef = useRef(null);
    const messagesWrapperRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false)

    const handleGetBack = () => {
        navigate("/support");
    };
    async function getMessages(ticket: { id: any; }) {
        const res = await axios.get(process.env.REACT_APP_URL + `/tickets/${ticket?.id}/messages`)
            .then((res) => {
                setMessages(res?.data.data)
            })
            .catch((err) => {
                if (err?.response?.status === 401) {
                    sendToAuth()
                }

                const stringErrorStatus = err?.response?.status.toString()
                if (stringErrorStatus.startsWith('5') || stringErrorStatus.startsWith('4')) {
                    navigate(ERoutes.MAIN)
                }
            })
    }

    async function handleCloseTicket(ticket: { id: any; }) {
        const res = await axios.post(process.env.REACT_APP_URL + `/tickets/close`, {ticket_id: ticket.id})
            .then((res) => {
                // init();
                // popup.open({
                //     title: 'Тикет закрыт',
                //     message: 'Ваше обращение успешно закрыто.',
                //     buttons: [{ id: 'my-id', type: 'ok'}],
                // })
                tg.showAlert(`Ваше обращение #${ticket.id} закрыто.`);
            })
            .catch((err) => {
                if (err?.response?.status === 401) {
                    sendToAuth()
                }

                const stringErrorStatus = err?.response?.status.toString()
                if (stringErrorStatus.startsWith('5') || stringErrorStatus.startsWith('4')) {
                    navigate(ERoutes.MAIN)
                }
            })
    }

    // Загружаем сообщения при монтировании компонента
    useEffect(() => {
        if (ticket) {
            getMessages(ticket);
        }
    }, [ticket]);

    // Скроллинг к последнему сообщению после загрузки данных
    useEffect(() => {
        setTimeout(() => {
            if (messagesWrapperRef.current) {
                const wrapper = messagesWrapperRef.current;
                wrapper.scrollTop = wrapper.scrollHeight - 55; // Отступ для MainButton
            }
        }, 0);
    }, [messages]); // Скроллим при изменении сообщений

    return (
        <>
            <FadeAnimation show={!messages}>
                <AbsolutePreloader />
            </FadeAnimation>
            <FadeAnimation show={!!messages}>
                <BackButton onClick={handleGetBack}/>
                <MessageListTitle>Тикет #{ticket.id}</MessageListTitle>
                {messages && (
                    <MessagesListWrapper ref={messagesWrapperRef}>
                        {messages.map((message, index) => {
                            const isMine = message.sender_id == localStorage.getItem('user_id');
                            return (
                                <MessageBox key={index} mine={isMine}>
                                    <MessageText mine={isMine}>
                                        <p>{message.text}</p>
                                    </MessageText>
                                    <MessageDateTime
                                        style={{color: "white"}}>{format(new Date(message.created_at), 'HH:mm dd.MM.yy')}</MessageDateTime>
                                </MessageBox>
                            );
                        })}
                        <div ref={messagesEndRef} style={{marginBottom: `55px`}}/>
                        {ticket.status === 'active'} && (
                            <MainButton text="Вопрос решен"
                                        progress={isLoading}
                                        disabled={isLoading}
                                        onClick={() => handleCloseTicket(ticket)}
                                        color={"#F5C71A"}
                                        textColor={"#000000"}
                            />
                        )
                    </MessagesListWrapper>
                )}
            </FadeAnimation>
        </>
    );
}