import {BackBtn} from "../TrainingPageContent/TrainingPageContent.styles";
import {ReactComponent as BackIcon} from "../TrainingPageContent/back.svg";
import {useNavigate} from "react-router-dom";
import {SupportPageInput, SupportPageTitle, SupportPageWrapper} from './SupportPage.styles'
import {FreezeDatesTypography, FreezeDatesWrapper, StyledDatePicker} from "../ProfilePage/ProfilePage.styles";
import axios from "axios";
import {ChangeEvent, useState} from "react";
import {useSendToAuth} from "../../hooks/useSendToAuth";
import {useAppSelector} from "../../hooks/useAppSelector";
// @ts-ignore
import DatePicker, {registerLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
import {BackButton, MainButton} from "@vkruglikov/react-telegram-web-app";
import ym from "react-yandex-metrika";
import { Button } from "antd";

registerLocale("ru", ru);

// @ts-ignore
const tg = window.Telegram.WebApp;


export const CreateSupportMessage = () => {
    interface SupportPageInputProps {
        exceeded: boolean; // Define the exceeded prop
    }

    const profile = useAppSelector(state => state.user.profile)
    const navigate = useNavigate();
    const today = new Date();
    const [inputValue, setInputValue] = useState("");
    const [exceededLimit, setExceededLimit] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const maxSymbols = 600;

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        if (value.length <= maxSymbols) {
            setInputValue(value);
            setExceededLimit(false);
        } else {
            setExceededLimit(true);
        }
    };
    const addDays = (days: number, date: Date) => {
        const newDate = new Date(date)
        newDate.setDate(newDate.getDate() + days);
        return newDate
    };
    const limit = addDays(5, today)
    const [SupportFrom, setSupportFrom] = useState(today);
    const [SupportUntil, setSupportUntil] = useState(limit);
    const sendToAuth = useSendToAuth();

    const handleGetBack = () => {
        navigate(-1)
    }

    async function handleSupport(inputData: string) {
        setIsLoading(true)
        const clubId = localStorage.getItem('club')
        axios.post(process.env.REACT_APP_URL + '/tickets/create', {
            text: inputData,
        })
            .then((response) => {
                const ticket = response.data.ticket;
                tg.showAlert(`Ваше обращение #${ticket} зарегистрировано.`);
                setIsLoading(false)
                ym('reachGoal',`support_200_${clubId}`)
            })
            .catch((err) => {
                if (err?.response?.status === 401) {
                    sendToAuth();
                }
                ym('reachGoal',`support_error_${clubId}`)
                setIsLoading(false)
            });
    }

    const handleFocus = (e: ChangeEvent<HTMLTextAreaElement>) => {
        e.target.blur();
    };

    // @ts-ignore
    return (
        <SupportPageWrapper>
            <BackButton onClick={handleGetBack}/>
            <SupportPageTitle>Поддержка</SupportPageTitle>
            <SupportPageInput exceeded={exceededLimit}>
          <textarea
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Введите ваш вопрос здесь..."
          />
                <div className="symbol-counter">
                    {inputValue.length}/{maxSymbols}
                </div>
            </SupportPageInput>
            {/*<Button onClick={() => handleSupport(inputValue)}></Button>*/}
            <MainButton
                text={"Отправить"}
                progress={isLoading}
                disabled={isLoading}
                onClick={() => handleSupport(inputValue)}
                color={"#E7E7E7"}
                textColor={"#000"}
            />
        </SupportPageWrapper>
    );
};