import styled, { css } from "styled-components";
import { rem } from "../../app/styles";

interface INavIcon {
  isSelected: boolean
}

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: ${rem(30)} ${rem(40)};
  border-top: var(--color-third) 1px solid;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  background: var(--color-primary);
`;

export const FooterTitle = styled.p`
  color: var(--color-text);
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  //font-family: 'Integral CF';
`;

export const UserAvatar = styled.div`
  border: 1px solid var(--color-third);
  border-radius: ${rem(35)};
  padding: ${rem(4)};
  align-items: center;
  display: flex;
  cursor: pointer;

  img {
    width: ${rem(36)};
    height: ${rem(36)};
  }

  &:active {
    border: 1px solid var(--color-text);
  }
`;

export const NavIcon = styled.div<INavIcon>`
  border: 1px solid var(--color-third);
  border-radius: ${rem(35)};
  padding: ${rem(10)};
  align-items: center;
  display: flex;
  cursor: pointer;

  img {
    width: ${rem(25)};
    height: ${rem(25)};
    border-radius: 50%;
  }
  
  ${({ isSelected }) => isSelected &&
    css`
      border: 1px solid var(--color-text);
    `
  }
`;
