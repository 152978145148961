import styled from 'styled-components';
import { rem } from '../../app/styles';
import { Input } from '../../ui-kit/Input';
import { Button } from 'antd';

export const AuthorizationPageLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2vh 0;
  gap: ${rem(100)};
  align-items: center;
  width: 100%;
  min-height: 100vh;
  position: relative;
`;

export const AuthorizationFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(100)};
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const AuthorizationContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(40)};
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const AuthorizationFormActions = styled.div`
  display: flex;
  gap: ${rem(25)};
  width: 100%;

  a {
    color: var(--color-text-dark);
    text-decoration: none;
  }

  justify-content: center;

  Button {
    flex: 0 0 20%;
  }
`;

export const PhoneInput = styled(Input)`
  display: flex;
  flex: 0 0 50%;
  border-bottom: var(--color-third) 2px solid;
`;

export const AppLogo = styled.h1`
  color: var(--color-text);
  text-align: center;
  font-size: 32px;
  font-weight: 400;
  line-height: 25px;
`;

export const AppTitle = styled.p`
  color: var(--color-text);
  font-size: 24px;
  font-weight: 800;
  line-height: 30px;

  svg {
    width: ${rem(144)};
    height: ${rem(56)};
  }
`;

export const AuthorizationTitle = styled.h4`
  color: var(--color-text);
  font-size: 20px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
`;

export const AuthorizationTypography = styled.p`
  color: var(--color-text);
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
`;

export const OtpVerificationWrapper = styled.div`
  padding: 10%;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${rem(45)};
    margin-top: 10%;

  button {
    margin-top: ${rem(25)};
    width: 80%;
    align-self: center;
  }

  .action {
    color: var(--color-action);
    text-align: center;
    font-size: ${rem(13)};
  }

  .text {
    color: var(--color-text);
    text-align: center;
    font-size: ${rem(13)};
  }
`;

export const OtpVerificationInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(15)};
  width: 100%;
`;


export const ConventionWrapper = styled.div`
  color: #969696;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px 36px;
  z-index: 200;
  width: 100%;
  font-size: 10px;
  border-top: 1px solid #969696;
`;

export const CloseConventionButton = styled(Button)`
  top: 10px;
  position: absolute;
  right: 10px;
  width: 18px !important;
  height: 18px !important;
  min-width: 18px !important;
  display: grid;
  place-content: center;

  &:hover {
    border-color: var(--color-primary) !important;
  }
  span {
    line-height: 0px;
  }
`;
