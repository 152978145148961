import {useNavigate} from "react-router-dom";
import axios from "axios";
import {ChangeEvent, useEffect, useState} from "react";
// @ts-ignore
import DatePicker, {registerLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
import {BackButton, MainButton} from "@vkruglikov/react-telegram-web-app";
import ym from "react-yandex-metrika";
import {BodyWrapper, PaymentSuccessfulPageWrapper} from "./PaymentSuccessfulPage.styles";

import {ReactComponent as VerifySvg} from "../PaymentStatusPages/verify.svg";
import {Button} from "@mui/material";
//@ts-ignore
const tg = window.Telegram.WebApp


export const PaymentSuccessfulPage = () => {

    const clubId = sessionStorage.getItem('club') || localStorage.getItem('club')
    const chatId = tg?.initDataUnsafe?.user?.id

    let buttonText;

    if (localStorage.getItem('token')) {
        buttonText = "В магазин услуг"
    } else {
        buttonText = "На главную"
    }

    const navigate = useNavigate();
    const handleGetBack = () => {
        if (localStorage.getItem('token')) {
            navigate('/shopping')
        } else {
            navigate('/')
        }
    }

    useEffect(() => {
        if (clubId && chatId) {  // Ensure that both clubId and chatId are available before making the request
            axios.post(process.env.REACT_APP_URL + '/send-success-not', {
                club_id: parseInt(clubId),
                chat_id: chatId.toString()
            })
                .then(response => {
                    console.log('Success notification sent:', response.data);
                })
                .catch(error => {
                    console.error('Error sending success notification:', error);
                });
        }
    }, [clubId, chatId]);


    return (
        <PaymentSuccessfulPageWrapper>
            <BackButton onClick={handleGetBack}/>
            <BodyWrapper>
                <VerifySvg/>
                <p style={{fontWeight: 'bold', color: 'white', marginTop: '10px'}}>Ваш платеж принят!</p>
                <p style={{color: 'white', marginTop: '10px', textAlign: 'center', fontSize: '14px'}}>В ближайшее время
                    с вами свяжется менеджер отдела продаж для подтверждения оформления услуги</p>
            </BodyWrapper>
            <Button
                href={'tg://'}
                style={{
                    padding: '10px 20px',
                    borderRadius: '14px',
                    border: 'none',
                    width: '100%',
                    background: '#F5C71A',
                    color: '#2C2C2E',
                    fontWeight: '600',
                }}
            >
                Вернуться в телеграм
            </Button>
            {/*<MainButton*/}
            {/*    text={buttonText}*/}
            {/*    onClick={() => handleGetBack()}*/}
            {/*    color={"#E7E7E7"}*/}
            {/*    textColor={"#000"}*/}
            {/*/>*/}
        </PaymentSuccessfulPageWrapper>
    )
}