import styled from "styled-components";
import {rem} from "../../app/styles";

export const OtpInputWrapper = styled.div`
  width: 100%;
  display: flex;
    margin-top: 50%;
`

export const OtpGroup = styled.div`
  display: flex;
  width: 100%;
  column-gap: ${rem(10)};
  align-self: center;
  justify-content: center;
  
  input {
    width: ${rem(43)};
    padding: ${rem(15)} ${rem(13)};
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    caret-color: transparent;
    border-radius: 0;
    color: var(--color-text);
    background: transparent;
    border: none;
    &:focus {
      border-bottom: 1px solid var(--color-action);
      border-top: none;
      border-right: none;
      border-left: none;
      border-radius: 0;
    }
    &::placeholder {
      font-size: ${rem(10)};
      transform: translateY(-40%);
    }
  }
`
