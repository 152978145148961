import {useNavigate} from "react-router-dom";
import axios from "axios";
import {ChangeEvent, useEffect, useState} from "react";

// @ts-ignore
import DatePicker, {registerLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import {BackButton, MainButton} from "@vkruglikov/react-telegram-web-app";
import {BodyWrapper, PaymentSuccessfulPageWrapper} from "./PaymentSuccessfulPage.styles";

import {ReactComponent as InformationSvg} from "../PaymentStatusPages/information.svg";

//@ts-ignore
const tg = window.Telegram.WebApp

export const PaymentErrorPage = () => {

    const clubId = sessionStorage.getItem('club') || localStorage.getItem('club')
    const chatId = tg?.initDataUnsafe?.user?.id

    let buttonText;

    if (localStorage.getItem('token')) {
        buttonText = "В магазин услуг"
    } else {
        buttonText = "На главную"
    }

    const navigate = useNavigate();
    const handleGetBack = () => {
        if (localStorage.getItem('token')) {
            navigate('/shopping')
        } else {
            navigate('/')
        }
    }

    useEffect(() => {
        if (clubId && chatId) {  // Ensure that both clubId and chatId are available before making the request
            axios.post(process.env.REACT_APP_URL + '/send-error-not', {
                club_id: clubId,
                chat_id: chatId
            })
                .then(response => {
                    console.log('Success notification sent:', response.data);
                })
                .catch(error => {
                    console.error('Error sending success notification:', error);
                });
        }
    }, [clubId, chatId]);


    return (
        <PaymentSuccessfulPageWrapper>
            <BackButton onClick={handleGetBack}/>
            <BodyWrapper>
                <InformationSvg/>
                <p style={{fontWeight: 'bold', color: 'white', marginTop: '10px'}}>Платеж отклонен!</p>
                <p style={{color: 'white', marginTop: '10px', textAlign: 'center', fontSize: '14px'}}>Произошла ошибка во время проведения платежа. Попробуйте позднее</p>
            </BodyWrapper>
            <MainButton
                text={buttonText}
                onClick={() => handleGetBack()}
                color={"#E7E7E7"}
                textColor={"#000"}
            />
        </PaymentSuccessfulPageWrapper>
    )
}