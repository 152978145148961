import { useEffect, useState } from 'react';
import { IScheduleTraining } from '../TrainingCard/model/TrainingCard.types';
import axios from 'axios';
import { useAppSelector } from '../../hooks/useAppSelector';
import qs from 'qs';
import { AbsolutePreloader } from '../../ui-kit/Preloader';
import {
  SchedulePageDatepicker,
  SchedulePageWrapper,
  ScheduleWrapper,
} from './SchedulePage.styles';
import { getDatesFromResponse } from '../../utils/getDatesFromResponse';
import Schedule from '../Schedule/Schedule';
import { FadeAnimation } from '../../ui-kit/FadeAnimation';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSendToAuth } from '../../hooks/useSendToAuth';
import Footer from '../Footer/Footer';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { Transition } from '@headlessui/react';

export interface IDaySchedule {
  date: string;
  schedule: IScheduleTraining[];
}

const queryUrl = process.env.REACT_APP_URL + '/schedule';

export const SchedulePage = () => {
  const navigate = useNavigate();
  async function fetchClubId() {
    axios
      .get(process.env.REACT_APP_URL + '/view-profile')
      .then((res) => {
        localStorage.setItem('club', res.data.data.club_id);
        console.log('Проверка ' + localStorage.getItem('club'));
      })
      .catch((err) => {
        sendToAuth();
        // if (err?.response?.status === 401) {
        //   sendToAuth();
        // } else {
        //   navigate('/error');
        // }
      });
  }

  const [month, setMonth] = useState<number>();
  const trainingFilter = useAppSelector((state) => state.schedule.title);
  const trainerFilter = useAppSelector((state) => state.schedule.employee);
  const timeFromFilter = useAppSelector((state) => state.schedule.timeFrom);
  const selectedDate = useAppSelector((state) => state.schedule.selectedDate);
  const dispatch = useAppDispatch();
  const sendToAuth = useSendToAuth();

  const location = useLocation();

  const [trainings, setTrainings] = useState();
  const [showAnimation, setShowAnimation] = useState(false);

  async function fetchSchedule() {
    try {
      await axios
        .get(queryUrl, {
          params: {
            title: trainingFilter,
            employee: trainerFilter,
            timeFrom: timeFromFilter,
          },
          paramsSerializer: (param) => {
            return qs.stringify(param, { arrayFormat: 'comma' });
          },
        })
        .then((res) => {
          return res;
        })
        .then((res) => setTrainings(res?.data));
    } catch (err) {
      sendToAuth();
      // if (err?.response?.status === 401) {
      //   sendToAuth();
      // } else {
      //   navigate('/error');
      // }
    }
  }

  const handleSelectDates = (
    date_from: string,
    date_to: string,
    month: number,
  ) => {
    setMonth(month);
  };

  useEffect(() => {
    void fetchSchedule();
    // void fetchClubId();
    // if (localStorage.getItem('club') === null) {
    //     void fetchClubId();
    // }
  }, [trainingFilter, trainerFilter]);

  return (
    <>
      <FadeAnimation show={Boolean(!trainings)}>
        <AbsolutePreloader />
      </FadeAnimation>
      <FadeAnimation show={Boolean(trainings)}>
        {trainings ? (
          <SchedulePageWrapper>
            <SchedulePageDatepicker dates={getDatesFromResponse(trainings)} />
            <ScheduleWrapper>
              <Transition
                key={location.hash}
                as={'div'}
                enter={'transition-opacity-in zindexEnter'}
                enterFrom={'opacity-100 background-enter zindexEnter'}
                enterTo={'opacity-0 background-leave zindexLeave'}
                leave={'transition-opacity-out zindexEnter'}
                leaveFrom={'opacity-0 background-leave zindexEnter'}
                leaveTo={'opacity-100 overlap background-enter zindexLeave'}
                show
                appear
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  zIndex: '1',
                  pointerEvents: 'none',
                }}
              />
              <Schedule schedule={trainings} date={selectedDate} />
            </ScheduleWrapper>
          </SchedulePageWrapper>
        ) : (
          <SchedulePageWrapper>
            <SchedulePageDatepicker dates={getDatesFromResponse(trainings)} />
            <FadeAnimation fullWidth animationKey={location.hash}>
              <ScheduleWrapper>
                <p>Тренировки не найдены</p>
              </ScheduleWrapper>
            </FadeAnimation>
          </SchedulePageWrapper>
        )}

        <Footer />
      </FadeAnimation>
    </>
  );
};
