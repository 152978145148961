import styled, { css } from "styled-components";
import { adaptiveValue, rem } from "../../app/styles";
import { FadeAnimation } from "../../ui-kit/FadeAnimation";
import { HashLink } from "react-router-hash-link";

export interface ISelectableDate {
  isSelected: boolean;
  isDisabled: boolean;
}

export const DatePickerContainer = styled.div`
  position: relative;
  width: 100%;
  padding: ${rem(25)};
  display: flex;
  flex-direction: column;
  gap: ${rem(25)};
  align-items: center;
  background: var(--color-bg);
  border-bottom-left-radius: ${rem(20)};
  border-bottom-right-radius: ${rem(20)};
  max-width: 100%;
  overflow: hidden;
`;

export const DatePickerNav = styled.div`
  display: flex;
  align-self: center;
  width: 100%;
  align-items: center;
  flex: 1 1 100%;
  margin-top: 80px;
`;

export const DatePickerBtn = styled.div`
  display: flex;
  flex: 1 1 33%;

  &:last-of-type {
    align-content: flex-end;
    flex-wrap: wrap;
  }

  svg {
    display: flex;
    flex: 1 1 100%;
    width: ${rem(28)};
    height: ${rem(28)};
  }
`;

export const FilterBtn = styled.div`
  border: 1px solid var(--color-text);
  border-radius: ${rem(15)};
  padding: ${rem(6)};
  display: flex;
  align-items: center;
  margin-left: ${rem(10)};

  svg {
    width: ${rem(15)};
    height: ${rem(15)};
  }
`;

export const SelectedMonth = styled.p`
  color: var(--color-text);
  font-size: ${rem(17)};
  font-weight: 600;
  line-height: normal;
  white-space: nowrap;
`;

export const DatePickerDays = styled(FadeAnimation)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${rem(10)} ${rem(20)};
  gap: ${rem(10)} ${adaptiveValue(20, 5, 475, 360)};
  padding-top: ${rem(5)};
  max-width: 100%;
`;

export const DatePickerDay = styled(HashLink)<ISelectableDate>`
  color: var(--color-text);
  background: var(--color-bg);
  border-radius: ${rem(20)};
  padding: ${rem(8)};
  min-width: ${rem(38)};
  text-align: center;
  display: flex;
  flex-direction: column;
  transition: 0.15s ease-in;
  background: var(--color-third);

  p {
    font-size: ${rem(17)};
    font-weight: 600;

    &:first-child {
      font-size: ${rem(13)};
      font-weight: 400;
    }
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background: var(--color-action);
      color: var(--color-primary);
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;
