import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { rem } from "../../app/styles";
import axios from "axios";
import {ModalWrapper, ModalTitle, ModalButtonWrapper, ModalContent, ModalBackground, CancelButton, YesButton, ModalButton } from "./BellModal.styles";
import {setAppointment} from "../../app/store/userSlice";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import ym from "react-yandex-metrika";

//@ts-ignore
const tg = window.Telegram.WebApp
interface ModalProps {
    trainingName: string;
    buttonStartTime: string;
    buttonStartDate: string;
    type: number;
    closeModal: () => void;
    AppointmentID: string;
    showModal: boolean
}



const BellModal = ({ showModal ,trainingName, buttonStartTime, buttonStartDate, closeModal, type, AppointmentID }: ModalProps) => {
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState(false)

    const subscribeAppointment = async function (AppointmentID: string, type: number) {
        setIsLoading(true)
        const clubId = localStorage.getItem('club')
        const result = await axios.post(process.env.REACT_APP_URL + '/subscribe-appointment', {AppointmentID, type}, {})
            .then(result => {
                closeModal();
                dispatch(setAppointment(AppointmentID))
                tg.showAlert('Вы успешно подписаны на уведомления!')
                if (type === 0) {
                    ym('reachGoal',`subscribe_appointment_start_200_${clubId}`)
                    console.log('РичГол: ' + `subscribe_appointment_start_200_${clubId}`)
                } else if (type === 1) {
                    ym('reachGoal',`subscribe_appointment_space_200_${clubId}`)
                }

            }).catch((error) => {
                if (error.response && error.response.status === 400) {
                    tg.showAlert('Вы уже подписаны на уведомления')
                }
                tg.showAlert('Что-то пошло не так..')
            }).finally(() => {
                setIsLoading(false)
            })
    }


    return (
        <>
            <ModalBackground showModal={showModal} onClick={closeModal} />
            <ModalWrapper showModal={showModal}>
                <ModalTitle>Подписаться на уведомления</ModalTitle>
                {type == 0 ?  (
                    <>
                        <ModalContent>
                            Запись на данное занятие будет доступно {buttonStartDate} с {buttonStartTime}
                        </ModalContent>
                        <ModalContent>
                            Вы желаете получить уведомление при открытии записи на занятие{" "}
                            {trainingName} ?
                        </ModalContent>
                    </>
                ) : (
                    <>
                        <ModalContent>
                            На данное занятие закончились свободные места
                        </ModalContent>
                        <ModalContent>
                            Вы желаете получить уведомление при появлении свободных мест на занятие{" "}
                            {trainingName} ?
                        </ModalContent>
                    </>
                )
                }

                <ModalButtonWrapper>
                    <YesButton variant={"primary"} isLoading={isLoading} onClick={async () => await subscribeAppointment(AppointmentID, type)}>Да</YesButton>
                    <CancelButton variant={"cancel-secondary"} onClick={closeModal}>Отмена</CancelButton>
                </ModalButtonWrapper>
            </ModalWrapper>
        </>
    );
};

export default BellModal;
