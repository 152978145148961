import {
  ProfilePageWrapper,
} from "../ProfilePage/ProfilePage.styles";

import { SupportPageWrapper, TicketWrapper, TicketId, TruncatedText, SupportPageTitle } from './SupportPage.styles'
import { useNavigate } from "react-router-dom";
import { ReactComponent as ShevronRight } from "../ProfilePage/ShevronRight.svg";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { FadeAnimation } from "../../ui-kit/FadeAnimation";
import { AbsolutePreloader } from "../../ui-kit/Preloader";
import { useSendToAuth } from "../../hooks/useSendToAuth";
import { ERoutes } from "../../app/AppRouter.types";
import { BackButton, MainButton } from "@vkruglikov/react-telegram-web-app";
import { ImageUploader } from "../../ui-kit/ImageUploader";
import Avatar from "../ProfilePage/user.png";
import { setUserProfile } from "../../app/store/userSlice";

// @ts-ignore
const tg = window.Telegram.WebApp;

export interface ITicket {
  id: string,
  text: string,
  status: string
}
export const SupportPage = () => {
  const navigate = useNavigate();
  const [tickets, setTickets] = useState<ITicket[]>([])
  const sendToAuth = useSendToAuth();
  const [isLoading, setIsLoading] = useState(false)

  const handleGetBack = () => {
    navigate("/profile");
  };

  const handleNewTicket = () => {
    navigate("/support/new-ticket")
  }

  const handleGoTicket = (ticket: ITicket) => {
    navigate(`/support/tickets/${ticket.id}`, { state: { ticket } });
  };

  async function getTickets() {
    const res = await axios.get(process.env.REACT_APP_URL + '/tickets')
      .then((res) => {
        setTickets(res?.data.data)
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          sendToAuth()
        }

        const stringErrorStatus = err?.response?.status.toString()
        if (stringErrorStatus.startsWith('5') || stringErrorStatus.startsWith('4')) {
          navigate(ERoutes.MAIN)
        }
      })
  }

  useEffect(() => {
    getTickets()
  }, [])

  return (
    <>
      <FadeAnimation show={!tickets}>
        <AbsolutePreloader />
      </FadeAnimation>
      <FadeAnimation show={!!tickets}>
        {tickets && (
          <SupportPageWrapper>
            <BackButton onClick={handleGetBack} />
            <SupportPageTitle>Поддержка</SupportPageTitle>
            {
              tickets
                .map((ticket, index) => (
                  <TicketWrapper onClick={() => handleGoTicket(ticket)}>
                    <TicketId status={ticket.status}>#{ticket?.id}</TicketId>
                    <TruncatedText>{ticket?.text}</TruncatedText>
                    <ShevronRight />
                  </TicketWrapper>
                ))}
            <MainButton text={"Новое обращение"}
                              progress={isLoading}
                              disabled={isLoading}
                              onClick={handleNewTicket}
                              color={"#F5C71A"}
                              textColor={"#000"}
                  />
          </SupportPageWrapper>
        )}
      </FadeAnimation>
    </>
  );
}