import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import {
  FilterVariant,
  FilterVariantWrapper,
  ShoppingPageTitle,
  ShoppingPageWrapper,
  Title,
  TrainerDropdownOptionItem,
  TrainerSelect,
  VariantsWrapper,
  YesButton,
} from './ShoppingPage.styles';
import axios from 'axios';
import { BackButton } from '@vkruglikov/react-telegram-web-app';
import { ReactComponent as CloseIcon } from './close-icon.svg';
import { ReactComponent as RubleSvg } from '../ShoppingPage/ruble.svg';
import serviceImage from '../ShoppingPage/5dbdc0835911016e2d380125.jpg';
import { FadeAnimation } from '../../ui-kit/FadeAnimation';
import { AbsolutePreloader } from '../../ui-kit/Preloader';
import { ReactComponent as CheckBox } from '../ShoppingPage/checkbox.svg';
import { ReactComponent as UncheckBox } from '../ShoppingPage/Uncheckbox.svg';
import { Select, Input, Space } from 'antd';
import { ERoutes } from '../../app/AppRouter.types';
import { ITrainer } from '../FiltersPage/TrainerFilter';
import { useDebounce } from '../../hooks/useDebounce';
import { calcAmount } from './lib/calc-amount';
import { Base64 } from 'js-base64';

// @ts-ignore
const tg = window.Telegram.WebApp;

Modal.setAppElement('#root');

interface Service {
  id: any;
  name: string;
  price: string;
  multiple: any;
  with_trainer: any;
}

export const ShoppingPage = () => {
  const navigate = useNavigate();

  const [servicesAbonement, setServicesAbonement] = useState<Service[]>([]);
  const [servicesPersonal, setServicesPersonal] = useState<Service[]>([]);
  const [selectedService, setSelectedService] = useState<Service | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [, setOrderId] = useState<number | null>(null);
  const [, setClientDid] = useState<number | null>(null);
  const [isChecked, setIsChecked] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [formClicked, setFormClicked] = useState(false);
  const [nextButtonLoading, setNextButtonLoading] = useState(false);
  const [variants, setVariants] = useState<ITrainer[]>([]);
  const [itemsCount, setItemsCount] = useState<number | null>(1);
  const [price, setPrice] = useState<number | null>(null);
  const [trainer, setTrainer] = useState<string | null>(null);
  const [discAmount, setDiscAmount] = useState<string | null>(null);
  const [promocodeId, setPromocodeId] = useState<number | null>(null);
  const [promocodeNotFound, setPromocodeNotFound] = useState(false);
  const [promoCodeInput, setPromoCodeInput] = useState('');
  const debouncedPromoCode = useDebounce(promoCodeInput, 500);
  const [clubId, setClubId] = useState<string| number | null>(null)

  const finalAmount = useMemo(() => {
    return calcAmount({
      amount: parseInt(selectedService?.price ?? '', 10),
      discAmount: parseInt(discAmount ?? '', 10),
      itemsCount: itemsCount || 1,
    });
  }, [discAmount, selectedService?.price, itemsCount]);

  const handleGetBack = () => {
    if (localStorage.getItem('token')) {
      navigate('/profile');
    } else {
      navigate('/');
    }
  };

  const fetchServicesAbonement = async () => {
    const clubId =
      localStorage.getItem('club') || sessionStorage.getItem('club');
    setClubId(clubId)
    try {
      const response = await axios.get(
        process.env.REACT_APP_URL +
          `/services/get-services?type=1&clubId=${clubId}`,
      );
      setServicesAbonement(response.data.data);
    } catch (error) {
      console.error('Failed to fetch services', error);
    }
  };

  const fetchServicesPersonal = async () => {
    const clubId =
      localStorage.getItem('club') || sessionStorage.getItem('club');
    try {
      const response = await axios.get(
        process.env.REACT_APP_URL +
          `/services/get-services?type=2&clubId=${clubId}`,
      );
      setServicesPersonal(response.data.data);
    } catch (error) {
      console.error('Failed to fetch services', error);
    }
  };

  const openModal = (service: Service) => {
    setSelectedService(service);
    setPrice(parseFloat(service.price));
    console.log(service);
    console.log(itemsCount);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsChecked(true);
    setIsModalOpen(false);
    setSelectedService(null);
    setDiscAmount(null);
    setPromocodeId(null);
    setPromocodeNotFound(false);
  };

  const handleGoShoppingCart = async () => {
    setFormClicked(true);

    if (!isChecked) {
      setHasError(true);
      return;
    }

    if (!selectedService) {
      console.error('No service selected');
      return;
    }

    const price = promocodeId ? finalAmount.discAmount : finalAmount.amount;
    let postData;
    let url;

    if (sessionStorage.getItem('club')) {
      url = process.env.REACT_APP_URL + '/create-order-new';
      postData = {
        service_id: selectedService.id,
        club: sessionStorage.getItem('club'),
        surname: sessionStorage.getItem('surname'),
        name: sessionStorage.getItem('name'),
        fathername: sessionStorage.getItem('fathername'),
        phone: sessionStorage.getItem('phone'),
        passport: sessionStorage.getItem('passport'),
        email: sessionStorage.getItem('email'),
        items_count: itemsCount,
        price: price.toString(),
        trainer: trainer,
        promocode_id: promocodeId,
      };
    } else {
      url = process.env.REACT_APP_URL + '/create-order';
      postData = {
        service_id: selectedService.id,
        items_count: itemsCount,
        price: price.toString(),
        trainer: trainer,
        promocode_id: promocodeId,
      };
    }

    try {
      const response = await axios.post(url, postData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Content-type': 'application/json',
        },
      });

      const data = response.data;
      const { id, user_id } = data.data;

      setOrderId(id);
      setClientDid(user_id);
      setNextButtonLoading(true);

      // Now that the order is created, encode the data and open the URL
      // const dataString = `${id}|${user_id}|${price}|${selectedService.name}`;
      // const utf8String = new TextEncoder().encode(dataString);
      // const binaryString = Array.from(utf8String)
      //   .map((byte) => String.fromCharCode(byte))
      //   .join('');
      // const base64EncodedData = btoa(binaryString);

      const dataString = `${id}|${user_id}|${price}|${clubId}`;
      const base64EncodedData = Base64.encode(dataString);
      const serviceName = encodeURI(selectedService.name);

      url = 'https://profitness-web-app.vercel.app';
      const urlCart =
         `${url}/shopping-cart?token=${base64EncodedData}&name=${serviceName}`;
      tg.openLink(urlCart);
      setNextButtonLoading(false);
    } catch (error) {
      console.error('Failed to create order', error);
      setHasError(true);
    }
  };

  const handleGetVariants = () => {
    const res = axios
      .get(process.env.REACT_APP_URL + '/get-trainers')
      .then((res) => {
        setVariants(res?.data?.Parameters);
      })
      .catch((err) => {
        if (err?.response?.status[0] === 4 || err?.response?.status[0] === 5) {
          navigate(ERoutes.MAIN);
        }
      })
      .finally(() => {});
  };

  const options = useMemo(() => {
    return variants.map((trainer) => ({
      value: trainer.ID,
      label: trainer.Name,
      item: trainer,
    }));
  }, [variants]);

  const handleItemsCountChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setItemsCount(Number(e.target.value));
  };

  const handleSelectTrainerChange = (value: string) => {
    setTrainer(encodeURIComponent(value));
  };

  const handleSetPromocodeValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPromoCodeInput(e.target.value);
    console.log(debouncedPromoCode);
  };
  const handleCheckPromocode = async (promoCode: string) => {
    let postData;
    let url;

    url = process.env.REACT_APP_URL + '/promocode';
    postData = {
      value: promoCode,
      amount: selectedService.price,
      service_id: selectedService.id
    };

    try {
      const response = await axios
        .post(url, postData, {
          headers: {
            'Content-type': 'application/json',
          },
        })
        .then((re) => {
          if (re.data.success === true) {
            setPromocodeNotFound(false);
            setDiscAmount(re.data.amount_discounted);
            setPromocodeId(re.data.promocode_id);

            console.log('id promo ' + promocodeId);
          } else {
            setDiscAmount(null);
            setPromocodeId(null);
          }

          console.log('ress ' + re.data.success);
        });
    } catch (error) {
      console.error('Failed to check promocode', error);
      setPromocodeNotFound(true);
      setDiscAmount(null);
      setPromocodeId(null);
    }
  };

  useEffect(() => {
    if (debouncedPromoCode) {
      handleCheckPromocode(debouncedPromoCode);
    } else {
      setDiscAmount(null);
      setPromocodeId(null);
      setPromocodeNotFound(false);
    }
    fetchServicesAbonement();
    fetchServicesPersonal();
    handleGetVariants();
  }, [debouncedPromoCode]);

  return (
    <>
      <FadeAnimation
        show={Boolean(
          !(servicesPersonal.length > 0 || servicesAbonement.length > 0),
        )}
      >
        <AbsolutePreloader />
      </FadeAnimation>
      <FadeAnimation
        show={Boolean(
          servicesPersonal.length > 0 || servicesAbonement.length > 0,
        )}
      >
        <ShoppingPageWrapper>
          {formClicked ? (
            <></>
          ) : (
            <>
              <BackButton onClick={handleGetBack} />
            </>
          )}
          <ShoppingPageTitle>Магазин услуг</ShoppingPageTitle>
          <Title>Абонементы</Title>
          <VariantsWrapper>
            {servicesAbonement.map((service) => (
              <FilterVariantWrapper
                key={service.name}
                onClick={() => openModal(service)}
              >
                <FilterVariant>{service.name}</FilterVariant>
                <FilterVariant style={{ justifyContent: 'end' }}>
                  {parseFloat(service.price).toLocaleString('ru-RU')}
                </FilterVariant>
                <RubleSvg style={{ width: '18px' }} />
              </FilterVariantWrapper>
            ))}
          </VariantsWrapper>
          <Title>Персональные тренировки</Title>
          <VariantsWrapper>
            {servicesPersonal.map((service) => (
              <FilterVariantWrapper
                key={service.name}
                onClick={() => openModal(service)}
              >
                <FilterVariant>{service.name}</FilterVariant>
                <FilterVariant style={{ justifyContent: 'end' }}>
                  {parseFloat(service.price).toLocaleString('ru-RU')}
                </FilterVariant>
                <RubleSvg style={{ width: '18px' }} />
              </FilterVariantWrapper>
            ))}
          </VariantsWrapper>
          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Оплата"
            style={{
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                padding: '20px',
                borderRadius: '14px',
                width: '370px',
                border: 'none',
                backgroundColor: '#2C2C2E',
              },

              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.75)', // темный фон за модальным окном
              },
            }}
          >
            {selectedService && (
              <div
                style={{
                  display: 'grid',
                  gridAutoRows: 'min-content',
                  gap: '15px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <h2 style={{ textAlign: 'left', color: 'white' }}>Оплата</h2>
                  <CloseIcon
                    onClick={closeModal}
                    style={{
                      cursor: 'pointer',
                    }}
                  />
                </div>
                <img
                  src={serviceImage}
                  alt="Service"
                  style={{
                    display: 'block',
                    borderRadius: '14px',
                    width: '100%',
                    height: '150px',
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                />
                <h3 style={{ textAlign: 'left', color: 'white' }}>
                  {selectedService.name}
                </h3>
                <div
                  style={{
                    border: '1px solid #F5C71A',
                    borderRadius: '14px',
                    padding: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    color: '#F5C71A',
                  }}
                >
                  <span>Стоимость</span>
                  {promocodeId ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <span
                        style={{
                          textDecoration: 'line-through',
                          color: 'white',
                        }}
                      >
                        {finalAmount.amount.toLocaleString('ru-RU')}
                      </span>
                      <span style={{ fontWeight: 'bold' }}>
                        {finalAmount.discAmount.toLocaleString('ru-RU')}
                      </span>
                    </div>
                  ) : (
                    <span style={{ fontWeight: 'bold' }}>
                      {finalAmount.amount.toLocaleString('ru-RU')}
                    </span>
                  )}
                </div>
                {selectedService.with_trainer === 1 && (
                  <TrainerSelect
                    dropdownStyle={{
                      backgroundColor: '#1C1C1E',
                      color: 'white',
                    }}
                    options={options}
                    placement={'topLeft'}
                    optionRender={(option) => {
                      return (
                        <TrainerDropdownOptionItem
                          selected={option.value === trainer}
                        >
                          {option.data.item?.name ?? option.data.label}
                        </TrainerDropdownOptionItem>
                      );
                    }}
                    placeholder={'Выберите тренера'}
                    size={'large'}
                    showSearch
                    filterOption={(inputValue, option) => {
                      return option.item.Name.toLowerCase().includes(
                        inputValue.toLowerCase(),
                      );
                    }}
                    onChange={handleSelectTrainerChange}
                  />
                )}
                {selectedService.multiple === 1 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <h3
                      style={{
                        textAlign: 'left',
                        color: 'white',
                      }}
                    >
                      Количество
                    </h3>
                    <Input
                      onChange={handleItemsCountChange}
                      defaultValue={itemsCount}
                      size={'large'}
                      style={{
                        width: '43px',
                        textAlign: 'center',
                        backgroundColor: '#1C1C1E',
                        color: 'white',
                        borderColor: '#1C1C1E',
                      }}
                    />
                  </div>
                )}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <h3
                    style={{
                      textAlign: 'left',
                      color: 'white',
                    }}
                  >
                    Промокод
                  </h3>
                  <Input
                    onChange={handleSetPromocodeValue}
                    size={'large'}
                    style={{
                      width: '50%',
                      textAlign: 'start',
                      backgroundColor: '#1C1C1E',
                      color: 'white',
                      borderColor: '#1C1C1E',
                    }}
                  />
                </div>
                {promocodeNotFound && (
                  <div style={{ textAlign: 'end' }}>
                    <span
                      style={{
                        color: '#969696',
                        fontSize: '13px',
                      }}
                    >
                      Указанный промокод не найден
                    </span>
                  </div>
                )}
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                >
                  <div
                    onClick={() => setIsChecked(!isChecked)}
                    style={{ cursor: 'pointer' }}
                  >
                    {isChecked ? (
                      <CheckBox />
                    ) : (
                      <UncheckBox
                        style={{
                          border: 'solid 1px #505050',
                          borderRadius: '8px',
                        }}
                      />
                    )}
                  </div>
                  <p
                    style={{
                      fontSize: '12px',
                      textAlign: 'start',
                      width: '100%',
                      color: hasError ? 'red' : '#969696',
                    }}
                  >
                    Оплачивая, вы принимаете условия{' '}
                    <a
                      href="https://powerbi.ravs.pro/storage/dogovor_profitness.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                      }}
                    >
                      сублицензионного договора-оферты
                    </a>
                  </p>
                </div>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <button
                    type="button"
                    onClick={closeModal}
                    style={{
                      padding: '10px 20px',
                      borderRadius: '14px',
                      border: 'none',
                      width: '40%',
                      background: '#fff',
                      color: '#2C2C2E',
                      fontWeight: '600',
                    }}
                  >
                    Закрыть
                  </button>
                  <YesButton
                    onClick={handleGoShoppingCart}
                    isLoading={nextButtonLoading}
                    variant={'primary'}
                  >
                    Далее
                  </YesButton>
                </div>
              </div>
            )}
          </Modal>
        </ShoppingPageWrapper>
      </FadeAnimation>
    </>
  );
};
