import {
  DisabledBellButton,
  TrainerAvatar,
  TrainerInfoContainer,
  TrainingAvatarWrapper, TrainingCommercialLabel,
  TrainingPageAvailable,
  TrainingPageContent,
  TrainingPageDesc, TrainingPageDescHeader,
  TrainingPageHeader,
  TrainingPageImg,
  TrainingPageInfo,
  TrainingPageMain,
  TrainingPageRoom,
  TrainingPageTitle,
  TrainingPageTrainer,
  TrainingPageTrainerRoomBlock,
  TrainingPageWrapper,
} from "./TrainingPageContent.styles";
import {ITrainingInfo} from "../TrainingCard/model/TrainingCard.types";
import {capitalizeName} from "../../utils";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {getMonthByNumber} from "../../utils/GetMonthByNumber";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {setAppointment} from "../../app/store/userSlice";
import React, {useEffect, useState} from "react";
import {ReactComponent as AvatarIcon} from '../Footer/user_footer.svg'
import {BackButton, MainButton} from "@vkruglikov/react-telegram-web-app";
import BellButton from "./Bell";
import {ReactComponent as DisabledBellIcon} from './disabled-bell.svg';
import {ModalWrapper, ModalTitle, ModalButtonWrapper, ModalContent, ModalBackground, CancelButton, YesButton, ModalButton } from "./ICalModal.styles";
import {AbsolutePreloader} from "../../ui-kit/Preloader";
import {FadeAnimation} from "../../ui-kit/FadeAnimation";
// import {Skeleton} from "@nextui-org/skeleton";
import Skeleton from "@mui/material/Skeleton"
import {rem} from "../../app/styles";
import 'react-loading-skeleton/dist/skeleton.css'
import ym from "react-yandex-metrika";


//@ts-ignore
const tg = window.Telegram.WebApp

tg.expand();

interface ITrainingPage {
  training: ITrainingInfo
  isActionsDisabled?: boolean
}

export const TrainingContent = ({training, isActionsDisabled}: ITrainingPage) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const handleGetBack = () => {
    navigate(-1)
  }
  const [isLoading, setIsLoading] = useState(false)

  const canAdd = training.canAdd
  const firstHalf = training.firstHalf
  const isToday = training.isToday
  const preEntryDateIsToday = training.preEntryDateIsToday
  const isAlreadyConducted = training.isConducted
  const preEntryDate = training.preEntryDate
  const hasFreePlace = Number(training.AvailableSlots) > 0
  const isAlreadySigned = training.isSigned
  const isAvailableToRegister = !isAlreadyConducted && hasFreePlace && canAdd
  const isSubscribed = training.isSubscribed

  const [showText, setShowText] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    setShowText(currentScrollY < 100);
  };

  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = training.Service.Photo;
    img.onload = () => setImageLoaded(true);
    img.onerror = () => setImageLoaded(true);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [training.Service.Photo]);

  const makeAppointment = async function (AppointmentID: string) {
    setIsLoading(true)
    const clubId = localStorage.getItem('club')

    const result = await axios.post(process.env.REACT_APP_URL + '/set-appointment', {AppointmentID}, {})
      .then(result => {
        if (result.data.Error !== 0) {
          tg.showAlert('Ошибка! Не удалось записаться на занятие')
          dispatch(setAppointment(AppointmentID))
          setIsLoading(false)
          ym('reachGoal',`make_appointment_error_other_${clubId}`)
          return
        }

        if (result.data.Error == -1) {
          tg.showAlert('Ошибка! Занятие не оплачено')
          dispatch(setAppointment(AppointmentID))
          setIsLoading(false)
          ym('reachGoal',`make_appointment_error_notPaid_${clubId}`)
          return
        }
        dispatch(setAppointment(AppointmentID))
        setIsLoading(false)
        setShowModal(true)
        ym('reachGoal',`make_appointment_200_${clubId}`)
      }).catch(() => {
        tg.showAlert('Что-то пошло не так..')
      })
  }

  const unSubscribeAppointment = async function (AppointmentID: string) {
    const clubId = localStorage.getItem('club')
    const result = await axios.post(process.env.REACT_APP_URL + '/unsubscribe-appointment', {AppointmentID}, {})
        .then(result => {
          tg.showAlert('Вы успешно отписались от уведомлений на занятие!')
          ym('reachGoal',`unsubAppointmentNotifications_${clubId}`)
        }).catch(() => {
          tg.showAlert('Что-то пошло не так..')
        }).finally(() => {
          dispatch(setAppointment(AppointmentID))
        })
  }

  let buttonStartDate = '';
  let buttonStartTime = '';

  if (!canAdd && !firstHalf && isToday) {
    buttonStartTime = "12:00";
  } else if (!firstHalf && !isToday) {
    buttonStartDate = `${training.StartDate.slice(-8, -5).trim()}.${training.StartDate.slice(-11, -9).trim()}`
    buttonStartTime = "12:00";
  } else if (!canAdd && firstHalf && preEntryDateIsToday) {
    buttonStartTime = "18:00";
  } else if (firstHalf && !preEntryDateIsToday) {
    buttonStartDate = preEntryDate
    buttonStartTime = "18:00"
  } else {
  }

  const event = {
    start: [2018, 5, 30, 6, 30],
    duration: { hours: 6, minutes: 30 },
    title: 'Bolder Boulder',
    description: 'Annual 10-kilometer run in Boulder, Colorado',
    location: 'Folsom Field, University of Colorado (finish line)',
    url: 'http://www.bolderboulder.com/',
    geo: { lat: 40.0095, lon: 105.2669 },
    categories: ['10k races', 'Memorial Day Weekend', 'Boulder CO'],
    status: 'CONFIRMED',
    busyStatus: 'BUSY',
    organizer: { name: 'Admin', email: 'Race@BolderBOULDER.com' },
    attendees: [
      { name: 'Adam Gibbons', email: 'adam@example.com', rsvp: true, partstat: 'ACCEPTED', role: 'REQ-PARTICIPANT' },
      { name: 'Brittany Seaton', email: 'brittany@example2.org', dir: 'https://linkedin.com/in/brittanyseaton', role: 'OPT-PARTICIPANT' }
    ]
  }

  const downloadIcal = async function (AppointmentID: string) {
    const result = await axios.get(`https://powerbi.ravs.pro/calendar?${training.Service.Title}&${training.StartDate}&${training.EndDate}&${training.Room.Title}`, { params: {
        name: training.Service.Title,
        from: training.StartDate,
        to: training.EndDate,
        address: training.Room.Title
      }})
        .then(result => {
          // tg.showAlert('Вы успешно отписались от уведомлений на занятие!')
        }).catch(() => {
          tg.showAlert('Что-то пошло не так..')
        }).finally(() => {
          dispatch(setAppointment(AppointmentID))
        })
  }

  async function handleDownload() {
    const name = training.Service.Title.replace(/\s+/g, '+').replace(/:/g, '%3A')
    const from = training.StartDate.replace(/\s+/g, '+').replace(/:/g, '%3A') + "%3A00"
    const to = training.EndDate.replace(/\s+/g, '+').replace(/:/g, '%3A') + "%3A00"
    const address = training.Room.Title.replace(/\s+/g, '+').replace(/:/g, '%3A')
    const contact_email = "service@profitness.club"
    const description = "Занятие+в+фитнес+клубе+PROФитнес"
      window.open(`https://powerbi.ravs.pro/calendar?name=${name}&from=${from}&to=${to}&address=${address}&contact_email=${contact_email}&description=${description}`, '_blank');
  }

  const cancelAppointment = async function (AppointmentID: string) {
    setIsLoading(true)
    const clubId = localStorage.getItem('club')
    const result = await axios.post(process.env.REACT_APP_URL + '/cancel-appointment', {AppointmentID}, {})
      .then((result) => {
        if (result.data.Error !== 0) {
          tg.showAlert('Невозможно отменить занятие за определенное время')
        }
        tg.showAlert('Запись на занятие успешно отменена')
        ym('reachGoal',`cancel_appointment_200_${clubId}`)
      }).catch(() => {
        tg.showAlert('Что-то пошло не так..')
          ym('reachGoal',`cancel_appointment_error_${clubId}`)
      }).finally(() => {
        dispatch(setAppointment(AppointmentID))
        setIsLoading(false)
      })
  }


  return (
    <TrainingPageWrapper>
      <BackButton onClick={handleGetBack}/>
      {imageLoaded ? (
          <TrainingPageImg
              src={training.Service.Photo}
          />) : (<Skeleton style={{
          objectFit: 'cover'
      }} animation="wave" variant="rectangular" width={'100%'} height={rem(250)}/>)
      }
      <TrainingPageContent>
        <TrainingPageHeader>
          <TrainingPageMain>
            <TrainingPageTitle>{training.Service.Title}</TrainingPageTitle>
          </TrainingPageMain>
          <TrainingPageAvailable
            isConducted={isAlreadyConducted}
          >{
            isAlreadyConducted ?
              'уже проведено' : `Осталось ${training.AvailableSlots} мест`
          }</TrainingPageAvailable>
        </TrainingPageHeader>
        <TrainingPageTrainerRoomBlock>
          <TrainerInfoContainer>
            <TrainingAvatarWrapper>
              {training.Employee.Photo ? <TrainerAvatar src={training.Employee.Photo}/> : <AvatarIcon/>}
            </TrainingAvatarWrapper>
            <TrainingPageTrainer>{capitalizeName(training.Employee.FullName)}</TrainingPageTrainer>
          </TrainerInfoContainer>
          <TrainingPageRoom>{training.Room.Title}</TrainingPageRoom>
        </TrainingPageTrainerRoomBlock>
        <TrainingPageInfo>
          <p>
            {training.Duration}
            <span>минут</span>
          </p>
          <p>
            {training.StartDate.slice(-5)}
            <span>{training.StartDate.slice(-8, -5)} {getMonthByNumber(Number(training.StartDate.slice(-11, -9)))}</span>
          </p>
          <p>
            {training.Capacity}
            <span>человек</span>
          </p>
        </TrainingPageInfo>
        <TrainingPageDesc>
          {training.Service.Description ? (
            <>
              <TrainingPageDescHeader>
                <p>Описание</p>
                {training.Commercial && (
                    <>
                    <TrainingCommercialLabel
                    >Платное</TrainingCommercialLabel>
                    </>
                )}
              </TrainingPageDescHeader>
              {training.Service.Description}
            </>
          ) : (
            <p>Описание отсутствует</p>
          )}
        </TrainingPageDesc>
      </TrainingPageContent>
      {!hasFreePlace && (
          <>
            {isSubscribed ? (
                <>
                  <DisabledBellButton onClick={async () => await unSubscribeAppointment(training.AppointmentID)}>
                    <DisabledBellIcon color={"#fc573b"}/>
                  </DisabledBellButton>
                </>
            ) : (
                <>
                  <BellButton type={1} AppointmentID={training.AppointmentID} trainingName={training.Service.Title} buttonStartDate={buttonStartDate} buttonStartTime={buttonStartTime}/>
                </>
            )}
            <MainButton text={"Мест нет"}
                        color={"#E7E7E7"}
                        textColor={"#000000"}
            />
          </>
      )}
      {isAlreadyConducted && (
        <MainButton text={"Занятие проведено"}
                    color={"#E7E7E7"}
                    textColor={"#000000"}
        />)}
      {!isAlreadyConducted && !isAvailableToRegister && (
        <>
          {!canAdd && (
              <>
              {isSubscribed ? (
                    <>
                      <DisabledBellButton onClick={async () => await unSubscribeAppointment(training.AppointmentID)}>
                        <DisabledBellIcon/>
                      </DisabledBellButton>
                    </>
                ) : (
                    <>
                      <BellButton type={0} AppointmentID={training.AppointmentID} trainingName={training.Service.Title} buttonStartDate={buttonStartDate} buttonStartTime={buttonStartTime}/>
                    </>
                )}
              <MainButton text={`Запись откроется ${training.availAppointmentDate} c ${training.availAppointmentTime}`}
                          color={"#E7E7E7"}
                          textColor={"#000000"}
              />
              </>
          )}
          {/*{!canAdd && !firstHalf && isToday ? (*/}
          {/*  <MainButton text={"Запись c 12:00"}*/}
          {/*              color={"#E7E7E7"}*/}
          {/*              textColor={"#000000"}*/}
          {/*  />*/}
          {/*) : !firstHalf && !isToday ? (*/}
          {/*  <MainButton*/}
          {/*    text={`Запись ${training.StartDate.slice(-8, -5).trim()}.${training.StartDate.slice(-11, -9).trim()} c*/}
          {/*      12:00`}*/}
          {/*    color={"#E7E7E7"}*/}
          {/*    textColor={"#000000"}*/}
          {/*  />*/}
          {/*) : null}*/}
          {/*{!canAdd && firstHalf && preEntryDateIsToday ? (*/}
          {/*  <MainButton*/}
          {/*    text={`Запись c 18:00`}*/}
          {/*    color={"#E7E7E7"}*/}
          {/*    textColor={"#000000"}*/}
          {/*  />*/}
          {/*) : firstHalf && !preEntryDateIsToday ? (*/}
          {/*  <MainButton*/}
          {/*    text={`Запись ${preEntryDate} c 18:00`}*/}
          {/*    color={"#E7E7E7"}*/}
          {/*    textColor={"#000000"}*/}
          {/*  />*/}
          {/*) : null}*/}
        </>
      )}
      {isAvailableToRegister && <>
        <MainButton text={isAlreadySigned ? "Отменить запись" : "Записаться"}
                    progress={isLoading || isActionsDisabled}
                    disabled={isLoading || isActionsDisabled}
                    onClick={isAlreadySigned ? async () => await cancelAppointment(training.AppointmentID) : async () => await makeAppointment(training.AppointmentID)}
                    color={isAlreadySigned ? "#FF2424" : "#F5C71A"}
                    textColor={"#000000"}
        />
      </>}
      <ModalBackground showModal={showModal} onClick={() => setShowModal(false)} />
      <ModalWrapper showModal={showModal}>
        <ModalTitle>Вы записаны!</ModalTitle>
              <ModalContent>
                Добавить событие в календарь?
              </ModalContent>
        <ModalButtonWrapper>
          <YesButton variant={"primary"} isLoading={isLoading} onClick={handleDownload}>Перейти</YesButton>
          <CancelButton variant={"cancel-secondary"} onClick={() => setShowModal(false)}>Отмена</CancelButton>
        </ModalButtonWrapper>
      </ModalWrapper>
    </TrainingPageWrapper>

  );
};