import {BackButton, MainButton} from "@vkruglikov/react-telegram-web-app";
import {AppTitle, AuthorizationTypography, OtpVerificationInfo, OtpVerificationWrapper, AuthorizationFormWrapper,} from "./Authorization.styles";
import {OtpInput} from "../../ui-kit/OtpInput";
import {phoneHandler} from "../../utils/phoneHandler";
import AuthorizationForm from "./AuthorizationForm";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import {authorizeUser} from "../../app/store/userSlice";
import ym from "react-yandex-metrika";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import { Button } from "antd";

//@ts-ignore
const tg = window.Telegram.WebApp;
const OtpPage = () => {
    const handleGetBack = () => {
        navigate('/')
    };

    const [requestId, setRequestId] = useState<string>('');
    const dispatch = useAppDispatch();
    const handleChange = (value: string) => setEnteredOtp(value);
    const navigate = useNavigate();
    const [enteredOtp, setEnteredOtp] = useState('');
    const [resendTime, setResendTime] = useState(0); // Countdown timer value in seconds
    const [isResendActive, setIsResendActive] = useState(false); // Flag to indicate whether the countdown is active
    const [isOtpReSent, setIsOtpReSent] = useState(false); // Flag to indicate whether OTP is already sent
    const params = new URLSearchParams(window.location.search);
    const phone = params.get('phone');


    useEffect(() => {
        const requestIdFromParams = params.get('request_id');
        if (requestIdFromParams) {
            setRequestId(requestIdFromParams);

            // Устанавливаем таймер только если его не было
            if (!isResendActive) {
                setResendTime(60);
                setIsResendActive(true);
            }
        }
    }, []);


    useEffect(() => {
        if (resendTime > 0 && isResendActive) {
            const timer = setInterval(() => {
                setResendTime((prevTime) => prevTime - 1);
            }, 1000);

            return () => clearInterval(timer);
        } else if (resendTime === 0 && isResendActive) {
            setIsResendActive(false);
        }
    }, [resendTime]);

    async function handleSendOtp(otp: string, phone: string) {
        const clubId = localStorage.getItem('club');
        try {
            const result = await axios
                .post(process.env.REACT_APP_URL + '/authenticate', {
                    request_id: requestId,
                    code: enteredOtp,
                })
                .then((res) => {
                    if (res.data.success) {
                        dispatch(authorizeUser({ phone: phone }));
                        localStorage.setItem('token', res.data.data.token);
                        localStorage.setItem('club', res.data.data.club_id);
                        localStorage.removeItem('otpStatus');
                        axios.defaults.headers.common['Authorization'] =
                            `Bearer ${localStorage.getItem('token')}`;
                        navigate('/schedule');
                        const clubId = localStorage.getItem('club');
                        ym('reachGoal', `successful_login_${clubId}`);
                    } else {
                        tg.showAlert('Неверный код, повторите попытку ввода');
                    }
                });
        } catch (e) {
            tg.showAlert('Что-то пошло не так..');
            ym('reachGoal', `login_error_${clubId}`);
            // console.log('Что-то пошло не так..')
        }
    }

    async function handleResendOtp() {
        let phoneNum = '+' + phone;
        try {
            const result = await axios.post(process.env.REACT_APP_URL + '/login', {
                phone: phoneNum.toString().replace('+', ''),
            });
            if (result.data.data.request_id) {
                setRequestId(result.data.data.request_id);
                setIsResendActive(true); // Start countdown timer
                setResendTime(60);
                setIsOtpReSent(true)
            }
        } catch (e) {
            // Handle error
        }
    }

    return (
        <AuthorizationFormWrapper>
    <BackButton onClick={handleGetBack} />
    <OtpVerificationWrapper>
        <OtpVerificationInfo>
            <AppTitle>ВЕРИФИКАЦИЯ</AppTitle>
            <AuthorizationTypography>
                Вам поступит уведомление с кодом в Telegram. Введите{' '}
                <b>4-x значный код</b> в поле ниже
            </AuthorizationTypography>
        </OtpVerificationInfo>
        <OtpInput
            value={enteredOtp}
            valueLength={4}
            onChange={handleChange}
        />
        {resendTime > 0 ? (
            <p className={'text'}>
                Отправить повторный звонок через:{' '}
                {String(Math.floor(resendTime / 60)).padStart(2, '0')}:
                {String(resendTime % 60).padStart(2, '0')}
            </p>
        ) : isOtpReSent ? (
            <p className={'text'}>Код отправлен</p>
        ) : (
            <p className={'action'}>
                <a onClick={handleResendOtp}>Отправить код повторно</a>
            </p>
        )}
        <MainButton
            text={'Применить'}
            disabled={!Boolean(enteredOtp.length === 4)}
            color={'#F5C71A'}
            textColor={'#000000'}
            onClick={async () =>
                await handleSendOtp(enteredOtp, phoneHandler(phone))
            }
        />
        {/*<Button*/}
        {/*  variant={'filled'}*/}
        {/*  disabled={!Boolean(enteredOtp.length === 4)}*/}
        {/*  onClick={async () =>*/}
        {/*    await handleSendOtp(enteredOtp, phoneHandler(phone))*/}
        {/*  }*/}
        {/*>*/}
        {/*  Далее*/}
        {/*</Button>*/}
    </OtpVerificationWrapper>
        </AuthorizationFormWrapper>
            )}

export default OtpPage;