import { TSchedule } from "../TrainingCard/model/TrainingCard.types";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useEffect, useMemo, useRef } from "react";
import { ScheduleDateBlock } from "../ScheduleDateBlock";
import { useAppSelector } from "../../hooks/useAppSelector";
import { setIsTransition, setSelectedDate } from "./schedule.slice";
import { FadeAnimation } from "../../ui-kit/FadeAnimation";
import { useLocation } from "react-router-dom";
import axios from "axios";

interface IScheduleProps {
  schedule: TSchedule;
  date: string;
}

export const Schedule = ({ schedule, date }: IScheduleProps) => {
  const dispatch = useAppDispatch();
  const rootRef = useRef<any>(null);
  const schedulesInView = useAppSelector(
    (state) => state.schedule.schedulesInView,
  );
  const selectedDate = useAppSelector((state) => state.schedule.selectedDate);

  const scheduleDates = useMemo(() => Object.keys(schedule), [schedule]);

  async function fetchClubId() {
    console.log('fetchClubId called');
    try {
      const res = await axios.get(process.env.REACT_APP_URL + '/view-profile');
      localStorage.setItem('club', res.data.club_id);
      console.log('Проверка ' + localStorage.getItem('club'));
    } catch (err) {
      console.log('Ошибка блин' + err)
    }
  }


  useEffect(() => {
    const isSomeDateInView = Object.values(schedulesInView).some(
      (scheduleInView) => scheduleInView.isInView,
    );

    if (!isSomeDateInView) {
      return;
    }

    const minDate = Object.values(schedulesInView).filter(
      (scheduleInView) => scheduleInView.isInView,
    )?.[0]?.date;

    console.log('smth', Object.values(schedulesInView).filter(
      (scheduleInView) => scheduleInView.isInView,
    ))

    void fetchClubId();

    if (selectedDate !== minDate) {
      dispatch(setSelectedDate(minDate));
    }


  }, [schedulesInView, selectedDate]);


  return (
    <div ref={rootRef}>
      {scheduleDates.map((scheduleDate) => (
        <ScheduleDateBlock
          root={rootRef?.current}
          date={scheduleDate}
          trainings={schedule[scheduleDate]}
        />
      ))}
    </div>
  );
};

export default Schedule;
