import axios from "axios";
import {useEffect, useMemo, useState} from "react";
import {
  MyTrainingsHeader,
  MyTrainingsPageContent,
  MyTrainingsTitle, MyTrainingsTitleNo,
  MyTrainingsWrapper,
} from "./MyTrainingsPage.styles";
import {TMyTrainingsSchedule} from "../TrainingCard/model/TrainingCard.types";
import {ScheduleCardWrapper, ScheduleDate} from "../Schedule/Schedule.styles";
import {useNavigate} from "react-router-dom";
import Footer from "../Footer/Footer";
import {FadeAnimation} from "../../ui-kit/FadeAnimation";
import {AbsolutePreloader} from "../../ui-kit/Preloader";
import {ERoutes} from "../../app/AppRouter.types";
import TrainingCard from "../TrainingCard/TrainingCard";

const queryUrl = process.env.REACT_APP_URL + "/get-appointments";

export const MyTrainingsPage = () => {
  const [myTrainings, setMyTrainings] = useState<TMyTrainingsSchedule | null>(null);

  const scheduleDates = useMemo(() => myTrainings ? Object.keys(myTrainings) : [], [myTrainings])

  const fetchMyTrainings = async function () {
    try {
      const response = await axios.get(queryUrl);
      // @ts-ignore
      setMyTrainings(response?.data);
    } catch (err) {
      const stringErrorStatus = err?.response?.status?.toString(); // Добавлена безопасная проверка

      if (stringErrorStatus && (stringErrorStatus.startsWith('5') || stringErrorStatus.startsWith('4'))) {
        navigate(ERoutes.MAIN);
      }
    }
  };

  useEffect(() => {
    void fetchMyTrainings();
  }, []);

  const navigate = useNavigate();

  const handleGetTrainingPage = (id: string) => {
    navigate(`/schedule/${id}`);
  };

  if (myTrainings && scheduleDates.length === 0) {
    return (
      // <MyTrainingsHeader>
      //
      // </MyTrainingsHeader>
        <>
      <MyTrainingsTitleNo>Нет записей на тренировки</MyTrainingsTitleNo>
      <Footer/>
        </>
    )
  }

  return (
    <>
      <FadeAnimation show={!myTrainings}>
        <AbsolutePreloader/>
      </FadeAnimation>
      <FadeAnimation show={Boolean(myTrainings)}>
        <MyTrainingsPageContent>
          <MyTrainingsHeader>
            <MyTrainingsTitle>Мои занятия</MyTrainingsTitle>
          </MyTrainingsHeader>
          <MyTrainingsWrapper>
            {myTrainings && (
              scheduleDates.map(trainingDate => (
                <>
                  <ScheduleDate>
                    {new Date(trainingDate).toLocaleDateString("ru-RU", {
                      day: "2-digit",
                      month: "long",
                    })}
                  </ScheduleDate>
                  <ScheduleCardWrapper>
                    {myTrainings[trainingDate].map(({
                                                      Service,
                                                      StartDate,
                                                      preEntry,
                                                      AppointmentID,
                                                      Duration,
                                                      Employee,
                                                      isSigned,
                                                      isConducted,
                                                      Commercial
                                                    }) => (
                      <TrainingCard
                        isConducted={isConducted}
                        title={Service.Title}
                        start_time={`${StartDate.slice(11)}`}
                        duration={Duration}
                        trainer={Employee.FullName}
                        isCancelled={preEntry}
                        id={AppointmentID}
                        onClick={() => handleGetTrainingPage(AppointmentID)}
                        isSigned={isSigned}
                        Commercial={Commercial}
                      />
                    ))}
                  </ScheduleCardWrapper>
                </>
              )))}
          </MyTrainingsWrapper>
          <Footer/>
        </MyTrainingsPageContent>
      </FadeAnimation>
    </>
  );
};
