import styled from 'styled-components';
import { rem } from '../../app/styles';
import { Button } from '../../ui-kit/Button';
import { Select, Space } from 'antd';

interface SupportPageInputProps {
  exceeded: boolean; // Define the exceeded prop
}

export const VariantsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex: 1 1 auto;
`;

export const FilterVariantWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-third);
`;

export const Title = styled.p`
  color: var(--color-text);
  font-size: ${rem(17)};
  margin-top: ${rem(10)};
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-self: flex-start;
`;

export const YesButton = styled(Button)`
  background-color: var(--color-action);
  color: var(--color-primary);
  width: ${rem(130)};
  font-size: ${rem(16)};
  border-radius: 14px;
`;
export const FilterVariant = styled.div`
  color: var(--color-text);
  width: 100%;
  height: ${rem(70)};
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  padding-left: ${rem(5)};
  padding-top: ${rem(20)};
  padding-bottom: ${rem(20)};
  margin: ${rem(2)};
  margin-right: ${rem(10)};
  display: flex;
  //align-self: end;
  align-items: center;
  justify-content: space-between;
`;

export const ShoppingPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(20)};
  padding: 5%;
  position: relative;
  padding-top: 80px;
`;

export const ShoppingPageTitle = styled.p`
  color: var(--color-text);
  text-align: center;
  font-size: ${rem(20)};
  font-weight: 800;
  line-height: ${rem(25)};
`;

export const SupportPageInput = styled.div<SupportPageInputProps>`
  display: flex;
  flex-direction: column;
  gap: ${rem(10)};
  margin-top: 40%;
  padding: ${rem(15)};
  align-self: flex-end;
  color: var(--color-text);
  font-size: ${rem(13)};
  font-weight: 400;
  line-height: ${rem(16)};
  border-radius: ${rem(20)};
  border: 2px solid var(--color-bg); /* Added border */
  width: 100%; /* Added width */
  position: relative;

  textarea {
    height: ${rem(250)}; /* Adjust the height accordingly */
    padding: ${rem(10)}; /* Added padding */
    margin-bottom: ${rem(20)};
    resize: none;
    font-size: ${rem(13)};
    font-weight: 400;
    line-height: ${rem(20)};
    outline: none;
    background-color: var(--color-primary);
    color: var(--color-text);
    word-break: break-word;
  }

  input::placeholder {
    color: var(--color-text); /* Set placeholder text color */
    vertical-align: top; /* Align placeholder to start at top position */
  }

  .symbol-counter {
    position: absolute;
    bottom: ${rem(10)};
    right: ${rem(10)};
    color: ${(props) => (props.exceeded ? 'red' : 'inherit')};
  }
`;

export const TrainerSelect = styled(Select)`
  display: block;
  width: 100%;

  > div {
    background-color: #1c1c1e !important;
    border-color: #1c1c1e !important;
  }

  .ant-select-selection-item {
    color: white !important;
  }

  .ant-select-selection-search {
    color: white !important;
  }

  .ant-select-arrow {
    color: white;
  }

  .ant-select-selection-placeholder {
    color: whitesmoke !important;
  }
`;

export const TrainerDropdownOptionItem = styled(Space)<{ selected?: boolean }>`
  color: ${({ selected }) => (selected ? 'black !important' : 'white')};
`;
