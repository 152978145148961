import { ERoutes, IRoute } from "./AppRouter.types";
import { SchedulePage } from "../components/SchedulePage/SchedulePage";
import { TrainingPage } from "../components/TrainingPage/TrainingPage";
import { MyTrainingsPage } from "../components/MyTrainingsPage/MyTrainingsPage";
import { AuthorizationPage } from "../components/AuthorizationPage/AuthorizationPage";
import { SettingsPage } from "../components/SettingsPage/SettingsPage";
import { ProfilePage } from "../components/ProfilePage/ProfilePage";
import { FiltersPage } from "../components/FiltersPage/FiltersPage";
import { TrainingFilter } from "../components/FiltersPage/TrainingFilter";
import { TrainerFilter } from "../components/FiltersPage/TrainerFilter";
import { FooterLayout } from "./layouts";
import { TimeFromFilter } from "../components/FiltersPage/TimeFromFilter";
import {ClubFilter} from "../components/FiltersPage/ClubFilters";
import {FreezePage} from "../components/FreezePage/FreezePage";
import {SupportPage} from "../components/SupportPage/SupportPage";
import {ErrorPage} from "../components/ErrorPage/ErrorPage";
import {ShoppingPage} from "../components/ShoppingPage/ShoppingPage";
import {PaymentSuccessfulPage} from "../components/PaymentStatusPages/PaymentSuccessfulPage";
import {PaymentErrorPage} from "../components/PaymentStatusPages/PaymentErrorPage";
import {RegisterForm} from "../components/RegisterPage/RegisterForm";
import {RegisterPage} from "../components/RegisterPage/RegisterPage";
import {ShoppingCartPage} from "../components/ShoppingCartPage/ShoppingCartPage";
import {MessagesList} from "../components/SupportPage/MessagesList";
import {CreateSupportMessage} from "../components/SupportPage/CreateSupportMessage"
import OtpPage from "../components/AuthorizationPage/OtpPage";

export const AuthorizedRoutes: IRoute[] = [
  {
    path: ERoutes.MAIN,
    element: <SchedulePage />,
    layout: FooterLayout,
  },
  {
    path: ERoutes.SCHEDULE,
    element: <SchedulePage />,
    layout: FooterLayout,
  },
  {
    path: ERoutes.FREEZE,
    element: <FreezePage />,
  },
  {
    path: ERoutes.SUPPORT,
    element: <SupportPage />,
  },
  {
    path: ERoutes.MESSAGES_LIST,
    element: <MessagesList />,
  },
  {
    path: ERoutes.NEW_TICKET,
    element: <CreateSupportMessage />,
  },
  {
    path: ERoutes.PROMOS,
    element: <SchedulePage />,
  },
  {
    path: ERoutes.TRAINING_PAGE,
    element: <TrainingPage />,
  },
  {
    path: ERoutes.MY_TRAININGS,
    element: <MyTrainingsPage />,
    layout: FooterLayout,
  },
  {
    path: ERoutes.SETTINGS,
    element: <SettingsPage />,
  },
  {
    path: ERoutes.PROFILE,
    element: <ProfilePage />,
  },
  {
    path: ERoutes.FILTERS,
    element: <FiltersPage />,
  },
  {
    path: ERoutes.TRAININGFILTER,
    element: <TrainingFilter />,
  },
  {
    path: ERoutes.TIMEFROMFILTER,
    element: <TimeFromFilter />,
  },
  {
    path: ERoutes.TRAINERFILTER,
    element: <TrainerFilter />,
  },
  {
    path: ERoutes.CLUB_FILTER,
    element: <ClubFilter />
  },
  {
    path: ERoutes.ERROR,
    element: <ErrorPage />
  },
  {
    path: ERoutes.REGISTER,
    element: <RegisterPage />,
  },
  {
    path: ERoutes.SHOPPING,
    element: <ShoppingPage />,
  },
  {
    path: ERoutes.PAYMENTSUCCESSFUL,
    element: <PaymentSuccessfulPage />,
  },
  {
    path: ERoutes.PAYMENTERROR,
    element: <PaymentErrorPage />,
  },
  {
    path: ERoutes.SHOPPINGCART,
    element: <ShoppingCartPage />,
  },
];

export const UnauthorizedRoutes: IRoute[] = [
  {
    path: ERoutes.MAIN,
    element: <AuthorizationPage />,
  },
  {
    path: ERoutes.REGISTER,
    element: <RegisterPage />,
  },
  {
    path: ERoutes.OTP,
    element: <OtpPage />,
  },
  {
    path: ERoutes.SHOPPING,
    element: <ShoppingPage />,
  },
  {
    path: ERoutes.PAYMENTSUCCESSFUL,
    element: <PaymentSuccessfulPage />,
  },
  {
    path: ERoutes.PAYMENTERROR,
    element: <PaymentErrorPage />,
  },
  {
    path: ERoutes.SHOPPINGCART,
    element: <ShoppingCartPage />,
  },
];
