import './styles/null.scss';
import './styles/App.scss';
import './styles/vars.scss';
import './fonts/Fontspring-DEMO-integralcf-bold.otf';
import { AppRouter } from "./app/AppRouter";
import { Provider } from "react-redux";
import store from "./app/store";
import axios from "axios";
import { SkeletonTheme } from "react-loading-skeleton";
import { WebAppProvider } from "@vkruglikov/react-telegram-web-app";
import { YMInitializer } from 'react-yandex-metrika';
import { init, miniApp, mountViewport, expandViewport, requestFullscreen, setMiniAppBackgroundColor, isFullscreen, viewport, setMiniAppHeaderColor, disableVerticalSwipes } from '@telegram-apps/sdk-react';
import { addToHomeScreen, checkHomeScreenStatus, retrieveLaunchParams } from '@telegram-apps/sdk';
import { defineEventHandlers, on, postEvent, subscribe, unsubscribe, type SubscribeListener } from '@telegram-apps/bridge';
import { Button, ConfigProvider, Space } from 'antd';
import { expand } from "@telegram-apps/sdk/dist/dts/scopes/components/viewport/methods/expand";
import { useEffect } from 'react';

function App() {
  axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

  useEffect(() => {
    // Проверяем, что Telegram Web App SDK загружен
    if (window.location.hash.slice(1)) {
      // Отправляем событие для расширения веб-приложения
        postEvent('web_app_expand');
        postEvent('web_app_request_fullscreen');
        postEvent('web_app_setup_swipe_behavior', { "allow_vertical_swipe": false });

    }
  }, []);

  return (
    <WebAppProvider>
      <Provider store={store}>
        <SkeletonTheme baseColor="#2C2C2E" highlightColor="#3A3A3C">
          <ConfigProvider
            theme={{
              components: {
                Select: {
                  selectorBg: '#1C1C1E',
                  activeBorderColor: '#1C1C1E',
                  optionSelectedBg: '#1C1C1E'
                },
              },
            }}
          ></ConfigProvider>
          <div className="App">
            <YMInitializer accounts={[97132345]} options={{ webvisor: true }} version="2" />
            <AppRouter />
          </div>
        </SkeletonTheme>
      </Provider>
    </WebAppProvider>
  );
}

export default App;
